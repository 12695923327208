<template>
    <div class="content">
        <b-form @submit.prevent="handleSubmit(doSubmit())">
        <div class="card">
            <div class="card-header bg-white" style="border-bottom: 1px solid rgba(0,0,0,.125);">
                <h6 class="card-title font-weight-semibold">Reservasi Pasien</h6>
            </div>
            <b-card-body>
                <VStepTab :step.sync="stepTab" :last="Steps.length" :config="stepValidate">
                    <template slot="link">
                        <template v-for="(v,k) in Steps">
                            <VStepLink :step.sync="stepTab" :option="k + 1" :key="k" :clickable="(k+1) < stepTab">
                                {{ v.subtitle }}
                            </VStepLink>
                        </template>
                    </template>
                    <template slot="content">
                        <template v-for="(v,k) in Steps">
                            <VStepContent :step.sync="stepTab" :option="k+1" :key="k">
                                <template slot="title">
                                    {{ v.subtitle }}
                                </template>
                                <template slot="fieldset">
                                    <div>
                                        <validation-observer
                                            ref="VFormCaraBayar"
                                        >
                                            <CaraBayar v-if="stepTab == 1" />
                                        </validation-observer>
                                        <validation-observer
                                            ref="VFormReservasiJadwal"
                                        >
                                            <ReservasiJadwal v-if="stepTab == 2" />
                                        </validation-observer>
                                        <validation-observer
                                            ref="VFormResumePendaftaran"
                                        >
                                            <ResumePendaftaran v-if="stepTab == 3" />
                                        </validation-observer>
                                        
                                    </div>
                                </template>
                            </VStepContent>
                        </template>
                    </template>
                    <template slot="action">
                        <ul role="menu" aria-label="Pagination">
                            
                            <button type="button" @click="back()" class="btn btn-light mr-3">Back</button>

                            <li :class="(stepTab==1?'disabled':'')" v-bind:aria-disabled="(stepTab==1?true:false)" @click="doPrev"><a href="javascript:" :class="'btn btn-light '+(stepTab==1?'disabled':'')" role="menuitem"><i class="icon-arrow-left13 mr-2"></i> Previous</a></li>

                            <li aria-hidden="false" aria-disabled="false" @click="doNext"><a href="javascript:" :class="'btn btn-primary '+(stepTab!=stepLast?'':'disabled')" role="menuitem">Next <i class="icon-arrow-right14 ml-2"></i></a></li>
                            

                            <li v-if="stepTab == stepLast" aria-hidden="false" aria-disabled="false"><button @click="doSubmit()"  type="button" class="btn btn-primary" role="menuitem">Submit<i class="icon-checkmark3 ml-2"></i></button></li>
                        </ul>
                    </template>
                </VStepTab> 
            </b-card-body>
        </div>
        </b-form>       
    </div>
</template>

<script>
import $ from 'jquery'
const _ = global._

import VStepTab from '@/components/VStep/VStepTab.vue'
import VStepLink from '@/components/VStep/VStepLink.vue'
import VStepContent from '@/components/VStep/VStepContent.vue'

import CaraBayar from './Form/CaraBayar.vue'
import ReservasiJadwal from './Form/ReservasiJadwal.vue'
import ResumePendaftaran from './Form/ResumePendaftaran.vue'

import Gen from '@/libs/Gen.js'

export default{
    props:{
        row:Object,
        mrValidation:Object,
        rowPasien:Object,
        listRujukan: Array,
    },
    components: {
      VStepTab, VStepLink, VStepContent, CaraBayar, ReservasiJadwal, ResumePendaftaran
    },
    computed: {
        stepValidate(){
            return {
                disabled:false,
                validate:true,
                refs:this.$children,
            }
        },
    },
    data(){
        return {
            stepTab: 1,
            stepLast: 3,
            Steps: [
                {
                    icon: 'create',
                    name: 'cara-bayar',
                    subtitle: 'Cara Bayar',
                    component: CaraBayar,
                    completed: false
                },
                {
                    icon: 'create',
                    name: 'reservasi-jadwal',
                    subtitle: 'Reservasi Jadwal',
                    component: ReservasiJadwal,
                    completed: false
                },
                {
                    icon: 'create',
                    name: 'resume-pendaftaran',
                    subtitle: 'Resume Pendaftaran',
                    component: ResumePendaftaran,
                    completed: false
                }
            ],
            mPasien: [],
            mAsuransi: [],
            mWaktuPeriksa: [
                {"text" : "Pagi (06:30 s/d 14:00)", "value": "06:30 s/d 14:00", "started": "06:30", 
                "ended" : "14:00"},
                {"text" : "Sore (14:00 s/d 17:00)", "value": "14:00 s/d 17:00", "started": "14:00", "ended" : "17:00"},
                {"text" : "Malam (17:00 s/d 22:00)", "value": "17:00 s/d 22:00", "started" :  "17:00", "ended" : "22:00"}
            ],
            mHariLibur: [],
            mPoli: [],
            mAlatBantu: [],
            mDokter: [],
            isBPJS: true,
        }
    },
    methods: {
        back(){
            this.$router.back()
        },
        doPrev(){
            if(this.stepTab !== 1){
                this.stepTab = this.stepTab -1
                this.row.arres_last_step = this.stepTab
                this.autoSave(this.row)
                window.scrollTo(0,0)
            }
        },
        doNext(){
            if(this.stepTab < this.stepLast){
                if(this.stepTab == 1){
                    if((this.row.apbd_status == 'MENINGGAL'|| this.row.apbd_status == 'KELUAR KEMAUAN SENDIRI'|| this.row.apbd_status == 'TIDAK DITANGGUNG' || this.row.apbd_status == 'PENANGGUHAN PESERTA' || this.row.apbd_status == 'NON AKTIF DIAKHIR BULAN' || this.row.apbd_status == 'PENANGGUHAN PEMBAYARAN' || this.row.apbd_status == 'NON AKTIF KARNA DATA GANDA' || this.row.apbd_status == 'USIA ANAK PPU > 21 TH DAN 25 TH' || this.row.apbd_status == 'NON AKTIF KARNA PREMI') && this.row.arres_jaminan == 3){
                        return this.$swal({
                            icon: 'error',
                            title: 'Mohon maaf Anda tidak bisa melanjutkan pendaftaran, karena status peserta BPJS Anda ' + this.row.apbd_status, 
                            text : 'Silakan konfirmasi ke cabang bpjs terkedat.'
                        })
                    }

                    if(!this.isBPJS && this.row.arres_jaminan == 3){
                        return this.$swal({
                            icon: 'error',
                            title: 'Tidak Bisa Lanjut', 
                            text : 'Data BPJS Tidak Ditemukan'
                        })
                    }
                    this.$refs['VFormCaraBayar'][0].validate().then(success=>{
                        if(success){
                            this.stepTab = this.stepTab + 1
                            this.row.arres_last_step = this.stepTab
                            this.autoSave(this.row)
                        }else{
                            setTimeout(()=>{
                                this.$parent.autoScrollValidate()
                            },500)
                        }
                    })
                }
                else if(this.stepTab == 2){
                    if(!(this.mDokter||[]).length && this.row.arres_type == 'Poli'){
                        return this.$swal({
                            icon: 'error',
                            title: 'Mohon maaf tidak ada kuota dokter', 
                        })
                    }
                    this.$refs['VFormReservasiJadwal'][0].validate().then(success => {
                        if(success){
                            this.stepTab = this.stepTab + 1
                            this.row.arres_last_step = this.stepTab        
                            this.autoSave(this.row)
                        }else{
                            setTimeout(()=>{
                                this.$parent.autoScrollValidate()                                
                            },500)
                        }
                    })
                }else if(this.stepTab == 3){
                    this.$refs['VFormResumePendaftaran'][0].validate().then(success => {
                        if(success){
                            this.stepTab = this.stepTab + 1
                            this.row.arres_last_step = this.stepTab                
                            this.autoSave(this.row)
                        }else{
                            setTimeout(()=>{
                                this.$parent.autoScrollValidate()
                            },500)
                        }
                    })
                }else{
                    console.log("no action")
                }
                window.scrollTo(0,0)
            }
        },
        doSubmit(){
            let inval = 0
            this.$refs['VFormCaraBayar'][0].validate().then(success=>{
                if(!success){
                    this.stepTab = 1
                    inval = inval + 1
                    this.row.arres_last_step = this.stepTab                
                    this.autoSave(this.row)
                    setTimeout(()=>{
                        this.$parent.autoScrollValidate()
                    },500)
                }
            })
            this.$refs['VFormReservasiJadwal'][0].validate().then(success=>{
                if(!success){
                    this.stepTab = 2
                    inval = inval + 1
                    this.row.arres_last_step = this.stepTab                
                    this.autoSave(this.row)
                    setTimeout(()=>{
                        this.$parent.autoScrollValidate()
                    },500)
                    
                }
            })
            this.$refs['VFormResumePendaftaran'][0].validate().then(success=>{
                if(!success){
                    this.stepTab = 3
                    inval = inval + 1
                    this.row.arres_last_step = this.stepTab                
                    this.autoSave(this.row)
                    setTimeout(()=>{
                        this.$parent.autoScrollValidate()
                    },500)
                }
            })
            
            setTimeout(()=>{
                if(inval){
                    return this.$swal({
                        icon: 'error',
                        title: 'Mohon Lengkapi Data Terlebih Dahulu'
                    }).then(result => {
                        if (result.value) {
                            setTimeout(()=>{
                        let inv = []
                        let el = document.querySelectorAll('.label_error')
                        for(let i = 0; i < (el||[]).length; i++){
                        if(el[i].style.display !== 'none'){
                            inv.push(el[i].id)
                        }
                        }
                        if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                    },500)
                        }
                    })
                }
                
                if(!inval){
                    this.$swal({
                        icon: 'warning',
                        title: 'Apakah Anda Yakin akan menyimpan data ini?',
                        text: this.row.ar_reg_code ? "Perubahan Data pada dokter, waktu periksa, dan poli akan mengubah nomor antrean" : "",
                        showCancelButton: true,
                        confirmButtonText: 'Ya',
                        cancelButtonText: 'Tidak, kembali'
                    }).then(result => {
                        if (result.value) {
                            let data = this.row
                            data.type = 'submit-data'

                            this.$parent.loadingOverlay = true
                            Gen.apiRest(
                                "/do/"+'RoReservasi',
                                {data:data}, 
                                "POST"
                            ).then(res=>{
                                this.$parent.loadingOverlay = false
                                let resp = res.data
                                resp.statusType = 200
                                this.$swal({
                                    title: resp.message,
                                    icon: resp.status,
                                    confirmButtonText: 'Ok',
                                    // allowOutsideClick: false,
                                    // allowEscapeKey: false
                                }).then(result => {
                                        console.log("test")
                                    if (result.value) {
                                        this.$socket.emit('refresh_data', {to : this.$parent.uPerawat})
                                        this.$router.push(
                                            { 
                                                name: 'RoReservasi', 
                                                params: {pageSlug: this.$route.params.pageSlug},
                                                query: {isDetail: true}
                                            })
                                        .catch(()=>{})
                                        // this.$router.push({name : 'RoReservasi'}).catch(()=>{})

                                    }
                                })
                            }).catch(err=>{
                                this.$parent.loadingOverlay = false
                                if(err){
                                    err.statusType = err.status
                                    err.status = "error"
                                    err.title = err.response?.data?.title
                                    err.message = err.response?.data?.message
                                    err.messageError = err.message

                                    if(err.title == "Terjadi gangguan koneksi pada server saat mengirim data"){
                                        this.$router.push({name : 'RoReservasi'}).catch(()=>{})
                                    }
                                }
                                this.$parent.doSetAlertForm(err)
                            })
                            
                            // 934?regId=1367
                        }
                    })
                }
            },100)

            
        },
        autoSave: _.debounce(function (data) {
            data.type = 'auto-save'
            if(!data.ar_reg_code){
                data.arres_last_step = this.row.arres_last_step
                Gen.apiRest(
                    "/do/"+this.$parent.modulePage,
                    {data:data}, 
                    "POST"
                )
            }
        },1000), 
        apiGetMaster(){
            let data = {
                poli_id : this.row.arres_poli,
                waktuPeriksa : this.row.arres_waktu_periksa,
                tgl_periksa : this.row.arres_tanggal_periksa,                
            }
            Gen.apiRest(
                "/get/"+this.$parent.modulePage+'/master', 
                {
                    params: data
                }
            ).then(res=>{
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })
            })
        },   
    },
    mounted() {
        setTimeout(()=>{
            this.apiGetMaster()
            this.stepTab = this.row.arres_last_step||1
        },1500)
    },
    watch: {
        row: {
            handler(v) {
                this.autoSave(v)
            },
            deep: true
        },
        'row.arres_jenis_penunjang'(v){
            if(v == 'FISIO'){
                setTimeout(()=>{
                    this.mPoli.push({
                        "text" : "INSTALASI REHABILITASI MEDIS",
                        "value" : 56
                    })
                    this.row.arres_poli = 56


                    let data = {
                        id: this.row.arres_poli,
                        waktuPeriksa: this.row.arres_waktu_periksa,
                        tgl_periksa: this.row.arres_tanggal_periksa,
                        type: 'get-by-poli'
                    }
                    
                    Gen.apiRest(
                    "/do/" + 'RoReservasi', {
                        data: data
                    },
                    "POST"
                    ).then(res => {
                        this.mDokter = res.data.mDokter
                    })

                },1500)
            }else{
                setTimeout(()=>{
                    let index = this.mPoli.findIndex(x=>x.value == 56)
                    if(index != -1){
                        this.row.arres_poli = null
                        this.mPoli.splice(index,1)
                    }
                },1500)
            }
        }
    }
}
</script>